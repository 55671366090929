import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import "react-circular-progressbar/dist/styles.css";

const ss = require("./../../assets/images/services/10.jpg");

function ServicesDetails() {
  const [img, setimg] = useState(ss);
  return (
    <>
      <Header />

      {/* <!-- Sidebar Page Container --> */}
      <div class="sidebar-page-container">
        <div class="auto-container">
          <div class="row">
            <div class="col-lg-4">
              <aside class="sidebar">
                {/* <!-- Category Widget --> */}
                <div class="sidebar-widget-two categories-widget-two">
                  <div class="widget-content">
                    <ul>
                      <li
                        onMouseEnter={() => {
                          setimg(ss);
                        }}
                      >
                        <Link>
                          Fizibilite Raporları <i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(ss);
                        }}
                      >
                        <Link>
                          Proje Hizmetleri <i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(ss);
                        }}
                      >
                        <Link>
                          Ölçüm Hizmetleri <i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(ss);
                        }}
                      >
                        <Link>
                          Abonelik İşlemleri<i></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </aside>
            </div>
            <div class="col-lg-8">
              <div class="services-details pl-lg-5">
                <div class="image-box">
                  <img src={img} alt="" />
                </div>
                <div class="content">
                  <div class="sec-title">
                    <h2>Proje ve Mühendislik Müşavirlik Hizmetleri</h2>
                  </div>
                  <blockquote>
                    <div class="text">
                      <div class="text">
                        Pier Enerji uzman mühendis kadromuz tarafından ulusal
                        akretide kuruluşları nezdinde resmi onaylar alınarak
                        projele vrinizi hazırlıyoruz. Yurt içinde resmi
                        presedürler, izin, ruhsat işlemleri tarafımızca
                        sağlanarak, anahtar teslim projeler gerçeklestiriyoruz.
                      </div>
                    </div>
                  </blockquote>

                  <div class="text">
                    Pier Enerji uzman mühendis kadromuz tarafından ulusal
                    akretide kuruluşları nezdinde resmi onaylar alınarak projele
                    vrinizi hazırlıyoruz. Yurt içinde resmi presedürler, izin,
                    ruhsat işlemleri tarafımızca sağlanarak, anahtar teslim
                    projeler gerçeklestiriyoruz.
                  </div>
                  <div class="text">
                    Enerji Uzmanları tarafından tasarlanan Pier MTTP, detayı
                    izleme, analiz ve raporlama altyapısı ile “TS EN ISO 50001
                    Enerji Yönetim Sistemi” kalite strandardı kapsamında
                    oluşturulan politikaların etki değerlendirilmesi ve analiz
                    edilmesine yönelik sağladığı verilerle, işletmelerin 50001
                    belgelendirmesi için gerekli olan ölçüm ve analiz
                    gereksinimlerinin tümünü karşılamaktadır.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default ServicesDetails;
