import React, { Component } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css";

const videobg1 = require("./../../assets/images/new-pics/video-bg.png");

class VideoPopup1 extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <>
        <section
          className="video-section"
          style={{ backgroundImage: "url(" + videobg1 + ")" }}
        >
          <div className="auto-container">
            <div
              className="default-video-box text-center wow zoomIn"
              data-wow-delay="200ms"
              data-wow-duration="1200ms"
            >
             
            </div>
            <div style={{fontSize:"45pt", marginBottom:"50px"}} className="sec-title light text-center">
              <div className="sub-title" style={{marginBottom:"2rem"}}><p style={{marginTop:"0.5rem",fontSize:"14pt"}}>Pier Enerji</p> </div>
        
              <h2 className="roboto" >EN İYİ HİZMET DENEYİMİ TAAHHÜT EDİYORUZ</h2>
            </div>
          </div>
        </section>

        <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="L61p2uyiMSo"
          onClose={() => this.setState({ isOpen: false })}
        />
      </>
    );
  }
}
export default VideoPopup1;
