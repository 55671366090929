import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import "react-circular-progressbar/dist/styles.css";

const dd = require("./../../assets/images/expimg-1.webp");

function ServicesDetails() {
  const [img, setimg] = useState(dd);
  return (
    <>
      <Header />

      {/* <!-- Sidebar Page Container --> */}
      <div class="sidebar-page-container">
        <div class="auto-container">
          <div class="row">
            <div class="col-lg-4">
              <aside class="sidebar">
                {/* <!-- Category Widget --> */}
                <div class="sidebar-widget-two categories-widget-two">
                  <div class="widget-content">
                    <ul>
                      <li
                        onMouseEnter={() => {
                          setimg(dd);
                        }}
                      >
                        <Link>
                          Gerçek Zamanlı İzleme ve Analiz <i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(dd);
                        }}
                      >
                        <Link>
                          Güç Kalitesinin Takibi <i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(dd);
                        }}
                      >
                        <Link>
                          Reaktif Güç Aşımı Takibi <i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(dd);
                        }}
                      >
                        <Link>
                          Yeşil Sertifika<i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(dd);
                        }}
                      >
                        <Link>
                          Operasyonel Karar Desteği<i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(dd);
                        }}
                      >
                        <Link>
                          Dijital İkiz <i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(dd);
                        }}
                      >
                        <Link>
                          TS EN ISO 50001 <i></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </aside>
            </div>
            <div class="col-lg-8">
              <div class="services-details pl-lg-5">
                <div class="image-box">
                  <img src={img} alt="" />
                </div>
                <div class="content">
                  <div class="sec-title">
                    <div class="sub-title">
                      Tesislerin enerji varlıklarının tek platformda izlenmesine
                      ve yönetimine imkân sağlayan dijital enerji verimliliği ve
                      ticaret platformudur.{" "}
                    </div>
                    <h2>Pier MTTP - Enerji verimliliği yönetim platformu </h2>
                  </div>

                  <blockquote>
                    <div class="text">
                      Tesisin enerji maliyetinin azaltılması, operasyonel
                      verimliliğin arttırılması, ve kanunen yapılması zorunlu
                      olan enerji verimliliği etüt raporlarının oluşturulmasına
                      olanak sağlanır.
                    </div>
                  </blockquote>

                  <div class="text">
                    İşletmelerin enerji verimliliği ve enerji tasarrufu
                    çalışmalarına altyapı niteliği taşıyan ve enerji hattının
                    anlık olarak tek bir merkezden izlenmesine, analiz
                    edilmesine ve raporlanmasına olanak sağlayan IoT Tabanlı,
                    ölçeklenebilir ve kullanıcı dostu bir enerji izleme sistemi
                    platformdur. Pier EİS ile bir tesisin elektrik hatlarını
                    dijitalleştirilerek burada olup bitenin 7x24 esaslı olarak
                    izlenebilmesine ve derinlemesine analiz edilebilmesine imkân
                    ağlanır. Gelişmiş uzaktan{" "}
                  </div>
                  <div class="text">
                    Okuma altyapısıyla toplanan veriler uçtan uca şifreli
                    biçimde güvenli bir şekilde merkezi sunuculara iletilerek,
                    tesisin tamamının veya bir bölümünün enerji sistemleri
                    gerçek zamanlı olarak izlenir, analitik modellerde koşan
                    yapay zekâ tabanlı algoritmalarla analiz edilir ve tesisin
                    operasyonel karar süreçlerinde ihtiyaç duyulan raporlara
                    dönüştürülerek paydaşlarımızın hizmetine sunulur.
                  </div>
                  <div class="text">
                    Tesiste üretilen ve tüketilen elektriğin ne kadarının, hangi
                    amaçla, nerede ve ne zaman kullanıldığının tespit edilmesi
                    ile enerji maliyetlerinin bölüm/üretim hattı/ekipmanlara
                    göre dağılımı, ürün başına enerji maliyet, enerji yoğunluğu,
                    makine performansı, üretim hattı izleme ve üretilen
                    elektriğin iç tüketimde kullanılma/şebekeye verilme miktarı
                    gibi birçok değerli verinin oluşturularak hizmetine
                    sunulmasıyla, paydaşlarımızın karar süreçlerinde ihtiyaç
                    duyacakları analitik içgörüye kolayca kavuşmaları
                    sağlanmaktadır.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default ServicesDetails;
