import React, { Component } from "react";
// import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
class Footer extends Component {
  render() {
    return (
      <>
        <footer className="main-footer">
          <div className="auto-container">
            <div className="widgets-section">
              <div className="row clearfix">
                <div className="column col-lg-4">
                  <div className="footer-widget logo-widget">
                    <img
                      className="widget-content"
                      width={"90%"}
                      src={require("../../assets/images/new-pics/footer-orange.png")}
                      alt=""
                    />
                    <img
                      width={"10%"}
                      className="facebook"
                      src={require("../../assets/images/new-pics/Facebook.png")}
                      alt=""
                    />
                    <img
                      width={"10%"}
                      className="twitter"
                      src={require("../../assets/images/new-pics/tw.png")}
                      alt=""
                    />
                    <img
                      width={"10%"}
                      className="intagram"
                      src={require("../../assets/images/new-pics/instagram.png")}
                      alt=""
                    />
                    <img
                      width={"10%"}
                      className="youtube"
                      src={require("../../assets/images/new-pics/youtube.png")}
                      alt=""
                    />
                    {/* <div className="widget-content"></div> */}
                  </div>
                </div>

                <div className="column col-lg-12">
                  <div className="footer-widget links-widget">
                    <div className="widget-content">
                      <Container fluid>
                        <Row style={{ marginLeft: "5%" }}>
                          <Col>
                            <Row>
                              <Col md={3}>
                                <img
                                  className="nav-img"
                                  style={{ marginLeft: "4vh" }}
                                  width={"60%"}
                                  src={require("../../assets/images/new-pics/Email.png")}
                                  alt=""
                                />
                              </Col>
                              <Col>
                                <div style={{ fontSize: "13pt" }}>
                                  E-mail Adresi
                                </div>
                                <strong style={{ fontSize: "13pt" }}>
                                  info@pierenerji.com
                                </strong>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <Col md={3}>
                                <img
                                  style={{ marginLeft: "4vh" }}
                                  width={"60%"}
                                  className="nav-img"
                                  src={require("../../assets/images/new-pics/Telefon.png")}
                                  alt=""
                                />
                              </Col>
                              <Col>
                                <div style={{ fontSize: "13pt" }}>
                                  Telefon Numarası
                                </div>
                                <strong style={{ fontSize: "13pt" }}>
                                  +90 216 969 3437
                                </strong>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <div
                          className="footer-border"
                          style={{ marginRight: "13%" }}
                        ></div>
                        <Row>
                          <Col md={3}></Col>
                          <Col md={1}>
                            <img
                              className="nav-img"
                              width={"100rem"}
                              src={require("../../assets/images/new-pics/Lokasion.png")}
                              alt=""
                            />
                          </Col>
                          <Col>
                            <p style={{ fontSize: "13pt", marginTop: "1vh" }}>
                              EsatPaşa mah. Ziya paşa Cad. No:6/1
                              Ataşehir/İstanbul
                            </p>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
