import React from "react";
import { Route, Routes, HashRouter } from "react-router-dom";
import Index from "./pages/index";
import Error404 from "./pages/404";
import About from "./pages/about";
import Contact from "./pages/contact";
import Portfolio1 from "./pages/portfolio-1";
import Services1 from "./pages/services-1";
import BackToTop from "./layout/backToTop";
import Project1 from "./pages/Project/Project1";
import Project2 from "./pages/Project/Project2";
import Project3 from "./pages/Project/Project3";
import Project4 from "./pages/Project/Project4";
import Project5 from "./pages/Project/Project5";
import Project6 from "./pages/Project/Project6";
import Project7 from "./pages/Project/Project7";
import Project8 from "./pages/Project/Project8";
import Project9 from "./pages/Project/Project9";
import Project10 from "./pages/Project/Project10";
import Project11 from "./pages/Project/Project11";
import Project12 from "./pages/Project/Project12";
import Project13 from "./pages/Project/Project13";
import Main from "./layout/main";
import Project14 from "./pages/Project/Project14";
import Project15 from "./pages/Project/Project15";
import Project16 from "./pages/Project/Project16";
import Servicedetay1 from "./pages/services-details-1";
import Servicedetay2 from "./pages/services-details_2";
import Servicedetay3 from "./pages/services-details_3";

// import EngIndex from "./pages/Eng-index";

function Router1() {
  const Tr = Index;
  // const En = EngIndex;
  return (
    <HashRouter>
      <div class="page-wrapper">
        <Routes>
          <Route path="/" element={<Main />}>
            <Route index element={<Tr />} />
            <Route path="about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/portfolio-1" element={<Portfolio1 />} />
            <Route path="/services-1" element={<Services1 />} />
            <Route path="portfolio-1/proje1" element={<Project1 />} />
            <Route path="portfolio-1/proje2" element={<Project2 />} />
            <Route path="portfolio-1/proje3" element={<Project3 />} />
            <Route path="portfolio-1/proje4" element={<Project4 />} />
            <Route path="portfolio-1/proje5" element={<Project5 />} />
            <Route path="portfolio-1/proje6" element={<Project6 />} />
            <Route path="portfolio-1/proje7" element={<Project7 />} />
            <Route path="portfolio-1/proje8" element={<Project8 />} />
            <Route path="portfolio-1/proje9" element={<Project9 />} />
            <Route path="portfolio-1/proje10" element={<Project10 />} />
            <Route path="portfolio-1/proje11" element={<Project11 />} />
            <Route path="portfolio-1/proje12" element={<Project12 />} />
            <Route path="portfolio-1/proje13" element={<Project13 />} />
            <Route path="portfolio-1/proje14" element={<Project14 />} />
            <Route path="portfolio-1/proje15" element={<Project15 />} />
            <Route path="portfolio-1/proje16" element={<Project16 />} />
            <Route path="*" element={<Error404 />} />
            <Route path="services-details_1" element={<Servicedetay1 />} />
            <Route path="services-details_2" element={<Servicedetay2 />} />
            <Route path="services-details_3" element={<Servicedetay3 />} />
          </Route>
        </Routes>
        <BackToTop />
      </div>
    </HashRouter>
  );
}

export default Router1;
