import React, { Component } from "react";
import { Nav, Tab } from "react-bootstrap";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Brand1 from "../element/brand1";
import PdfPopup from "../element/pdf-popup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class About extends Component {
  render() {
    return (
      <>
        <Header />

        <section className="page-banner">
          <div
            className="page-banner-bg abt-bg"
            
          ></div>
          <div className="bottom-rotten-curve alternate"></div>

          <div className="auto-container">
            <h1
            className="abt-text"
              style={{
                fontSize: "60px",
                textAlign: "center",
                marginLeft: "-300px",
              }}
            >
              Hakkımızda
            </h1>
          </div>
        </section>

        {/* About Section Two */}
        <section className="why-chooseus-section">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="content-box">
                  <div className="sec-title">
                    <h2
                      style={{
                        color: "#5c5c5c",
                        lineHeight: "58pt",
                        fontSize: "50pt",
                      }}
                    >
                      {" "}
                      EN İYİ HİZMET DENEYİMİNİ <br /> TAAHHÜT <br /> EDİYORUZ
                    </h2>
                    <div
                      style={{
                        color: "#5c5c5c",
                        fontSize: "13pt",
                      }}
                      className="text"
                    >
                      Temelleri 2016 yılında atılan PİER Enerji, elektrik
                      üretiminden son kullanıcıya uzanan elektrik değer
                      zincirinin her noktasında mühendislik hizmeti vermektedir.
                      PİER Enerji sunmuş olduğu kaliteli hizmet anlayışını,
                      piyasa tecrübesi ve uzman kadrosunun maksimum verimlilik
                      ilkesinden almaktadır. Elektrik taahhüt hizmetlerinde
                      kaliteli bakış açısı ile proje ve teknik gereksinimleri
                      yerine getirmeyi şiar edinen Pier Enerji; Üs Yapıda Otel,
                      Ticaret Merkezi, Toplu Konut, Okul gibi bir çok projede
                      anahtar teslim çözümler sunarken, Altyapı ve Endüstriyel
                      tesislerde dizayn ofis, etkin personel verimliliği ve
                      proje yönetimi konuları ile öne çıkmaktadır. Pier Enerji,
                      sunmuş olduğu kaliteli hizmet anlayışı, inovatif çözümler
                      prensibi, verimlilik ve etkinlik yapısı ile projelere
                      değer katmaktadır.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="image-wrapper">
                  <div className="image-one">
                    <img
                      src={require("../../assets/images/about/about-1.png")}
                      alt=""
                    />
                  </div>
                  <div className="image-two">
                    <img
                      src={require("../../assets/images/about/about-2.png")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about-section-two">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="image-wrapper">
                  <div className="image-one">
                    <PdfPopup />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="content-box">
                  <Tab.Container defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav nav-tabs tab-btn-style-one"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">
                          <span>Misyonumuz</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          <span>Vizyonumuz</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">
                          <span>Değerlerimiz</span>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content className="tabs-content">
                      <Tab.Pane eventKey="first" className="fadeInUp animated">
                        <div className="clearfix">
                          <div className="text">
                            <p
                              style={{
                                marginTop: "17px",
                              }}
                            >
                              2017 yılından bu zamana kadar tamamlamış olduğumuz
                              projelerle, yenilikçi yönetim anlayışıyla Pier
                              Enerji olarak vizyonumuz; elektrik taahhüt, enerji
                              yönetimi ve enerji ticareti alanlarında, ileri
                              teknolojiye dayalı mühendislik hizmeti sunan,
                              özgün çözüm ve ürünlere sahip, globalde lider
                              firma olmaktır.
                            </p>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second" className="fadeInUp animated">
                        <div className="clearfix">
                          <div className="text">
                            <p
                              style={{
                                marginTop: "17px",
                              }}
                            >
                              Yürürlükteki yasa, yönetmelik ve ilgili
                              mevzuatlara uygun bir biçimde, etik kurullar ve
                              şirket değerleri doğrultusunda; çalışan
                              memnuniyetinin sağlandığı, uygulamış olduğumuz iş
                              güvenliği, teknolojik gelişmeleri takip etmenin
                              rekabetin ön koşulu olduğu bilinciyle, yüksek
                              verimlilikle ve sürdürülebilir mühendislik
                              çözümleri ile performanslı, kaliteli, etkin ve
                              güvenilir çözümler geliştirmek, endüstriyel
                              kalkınma dönüşümüne öncülük etmekle beraber
                              müşterilerimize en iyi ve kaliteli hizmeti
                              sunmaktır.
                            </p>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third" className="fadeInUp animated">
                        <div className="clearfix">
                          <div
                            className="text"
                            style={{
                              marginTop: "17px",
                            }}
                          >
                            Gerçekleştirdiğimiz her projenin temelinde,
                            tanımlanmış şirket değerlerimizden rekabetçilik,
                            verimlilik, yenilikçilik, paydaş memnuniyeti ve etik
                            değerlere bağlılık yatar. Üstlendiğimiz projelerde,
                            bu değerlerin göz ardı edilmemesi gerektiğini bilir
                            ve buna göre çalışırız. Değerlerimize bağlı
                            çalıştığımızda, hizmet sağladığımız sektörde, daha
                            fazla başarıya ulaşacağımızı biliriz.
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="about-pier-bg">
          <Container
            fluid
            style={{
              width: "70%",
            }}
          >
            <div className="sec-title light text-center">
              <div className="sub-title">
                <p style={{ fontSize: "22pt", marginTop: "-1px" }}>
                  Pier Enerji
                </p>
              </div>
            </div>
            <Row>
              <Col>
                <h2 className="text-center">Pier MTTP</h2>
                <p
                  style={{
                    fontSize: "13pt",
                    textAlign: "justify",
                    marginBottom: "90px",
                  }}
                >
                  Pier MTTP ürünü, dağınık bir enerji varlık yönetim sisteminin
                  altyapısını sağlamaktadır. Organize sanayi bölgeleri,
                  konutlar, çatı güneş enerjisi, rüzgâr türbinleri ve büyük
                  ölçekli enerji depolama sistemleri gibi varlıklara sahip
                  endüstriyel üretim tesisleri yaratılacak ekosistemin anlık
                  izleme ve elektrik enerji varlık değer takasına imkân sunan
                  bir dijital ticaret platformudur.
                </p>
              </Col>
              <Col md={1}></Col>
              <Col>
                <h2 className="text-center">Pier ETS</h2>
                <p
                  style={{
                    fontSize: "13pt",
                    textAlign: "justify",
                    marginBottom: "90px",
                  }}
                >
                  Pier ETS, Tessilerin enerji üretim ve tüketimlerinin anlık
                  izlenmesi, yönetilmesi ve sistem performans etkinliğinin
                  optimizasyonunu sağlayan bir enerji izleme
                  platformudur.Kullanıı dostu ekran ara yüzleri sayesinde; son
                  kullanıcıya sistem güç durumuna ilişkin anlık raporlama
                  yeteneğiyle, yönetimsel kararların sürdürülebilir etkinliğine
                  katkı sunmaktır.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        {/* History Section */}
        <section className="history-section">
          <div className="auto-container">
            <div className="sec-title text-center light">
              <div className="sub-title">
                {" "}
                <p style={{ marginTop: "0.5rem", fontSize: "14pt" }}>
                  Tarihçe
                </p>{" "}
              </div>
              <h2>Hikayemiz</h2>
            </div>
            <Tab.Container defaultActiveKey="first">
              <Nav className="nav-tabs tab-btn-style-one">
                <Nav.Item>
                  <Nav.Link eventKey="first">2022</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">2021</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">2020</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fourth">2019</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fifth">2018</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="sixth">2017</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-5">
                      <div className="image">
                        <img
                          width="80%"
                          src={require("../../assets/images/about/2022.png")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="content pl-lg-4">
                        <div className="sec-title light"></div>
                        <div className="text roboto">
                          <p style={{ fontWeight: "bolder", fontSize: "20pt" }}>
                            <strong style={{ fontSize: "50px" }}>5.000</strong>{" "}
                            konut tamamlama projesi hedefine ulaşıldı.
                          </p>{" "}
                          <p style={{ fontSize: "18pt" }}>
                            {" "}
                            Pier MTTP dijital enerji ticaret platformu
                            projesinin teknik süreçleri tamamlandı.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="second" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-5">
                      <div className="image">
                        <img
                          width="80%"
                          src={require("../../assets/images/about/2021.png")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title light"></div>
                        <div className="text" style={{ fontSize: "18pt" }}>
                          Kırıkkale 300 iş günü süreli proje kapsamında 7 blok,
                          124 konutluk modern bir yaşam kompleksinin zayıf akım,
                          kuvvetli akım ve elektronik sistemleri tesis edildi.
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="third" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-5">
                      <div className="image">
                        <img
                          width="80%"
                          src={require("../../assets/images/about/2020.png")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title light"></div>
                        <div className="text" style={{ fontSize: "18pt" }}>
                          Karaman 133.000 m2 24 blok, 820 konut modern bir yaşam
                          kompleksinin zayıf akım, kuvvetli akım, otomasyon ve
                          elektronik sistemleriyle anahtar teslim projesi
                          tamamlandı.
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="fourth" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-5">
                      <div className="image">
                        <img
                          width="80%"
                          src={require("../../assets/images/about/2019.png")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title light"></div>
                        <div className="text" style={{ fontSize: "18pt" }}>
                          Konya 600 iş günü süreli proje kapsamında 24 blok, 609
                          konut ve ticaret merkezi modern bir yaşam kompleksinin
                          zayıf akım, kuvvetli akım, otomasyon ve elektronik
                          sistemleri; Pier MTTP dijital enerji ticaret platformu
                          projesi başladı.
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="fifth" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-5">
                      <div className="image">
                        <img
                          width="80%"
                          src={require("../../assets/images/about/2018.png")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title light"></div>
                        <div className="text" style={{ fontSize: "18pt" }}>
                          Aralık ayında 1043 konut, 60 derslikli lise ve 32
                          derslikli ilköğretim okul alanının altyapı, üstyapı
                          anahtar teslim ilk projeye başladı.
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="sixth" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-5">
                      <div className="image">
                        <img
                          width="100%"
                          F
                          src={require("../../assets/images/about/2017.png")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title light"></div>
                        <div className="text" style={{ fontSize: "18pt" }}>
                          <strong style={{ fontSize: "28px" }}>
                            Pier Enerji ve Sanayi Ltd. Şti. Türkiye’ de
                          </strong>
                          <br /> 2017 yılında enerji ticareti danışmanlığı,
                          elektrik taahhütlük hizmetleri ve elektrik proje
                          hizmetleri alanlarında faaliyet göstermek için
                          kuruldu.
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </section>

        {/* Team Section */}

        {/* Services Section Four */}
        <div className="about-policy-bg">
          <Container fluid className="about-policy-container">
            <Row>
              <Col md={4} sm={12} className="about-policy">
                <img
                  style={{ display: "block", margin: "auto auto 2vh auto" }}
                  src={require("../../assets/images/new-pics/welcome-3.png")}
                  alt=""
                />
                <br />
                <h4 className="text-center">KALİTE POLİTİKAMIZ</h4>
                <li>
                  Yürürlükte var olan yasa, yönetmelik ve mevzuatlara uymak.
                </li>
                <li>Ticari ahlak anlayışını, iş kazancının önüne çıkarmak.</li>
                <li>
                  Teknolojik gelişmelere ayak uydurup, gerisinde kalmamak.
                </li>
                <li>
                  Şirket menfaatlerinin, müşteri memnuniyetinden kaynaklı
                  olduğunun farkına varmak
                </li>
                <li>
                  Müşteri odaklı kalite yönetim sistemimizden ödün vermemek.
                </li>
                <li>
                  Uluslararası standartları takip etmek ve tamamlanan projelerde
                  standartlardan ödün vermemek.
                </li>
                <li>
                  Tedarikçilerimiz kalite düzeylerini denetlemek ve yükseltmek.
                </li>
                <li>
                  AR-GE ve inovasyon temelli olarak sürdürülebilir gelişim
                  sağlamak.
                </li>
                <li>
                  İş hacmini büyüterek Türkiye'de edinilmiş bilgi ve birikimle,
                  yurtdışı işler yaparak ülke ekonomisinde katkı sağlamak
                </li>
              </Col>
              <Col md={4} sm={12} className="about-policy">
                <img
                  style={{ display: "block", margin: "auto auto 2vh auto" }}
                  src={require("../../assets/images/new-pics/welcome-2.png")}
                  alt=""
                />
                <br />
                <h4 className="text-center">
                  İŞ SAĞLIĞI VE GÜVENLİĞİ POLİTİKAMIZ
                </h4>
                <li>
                  Projelerimizi tamamlarken, yürürlükte bulunan iş sağlığı ve
                  güvenliği ile ilgili yasa, tüzük, yönetmelik ve mevzuatlara
                  uymak.
                </li>
                <li>
                  İş sağlığıve güvenliği konusunda bilinç oluşturmak ve bunu
                  yaygınlaşabilir hale getirmek.
                </li>
                <li>
                  Sağlıklı ve güvenilir bir çalışma ortamı yaratmak ve
                  devamlılığını sağlamak.
                </li>
                <li>
                  Kaza sıklık oranı ve kaza ağırlık oranlarını değerlendirip,iş
                  kazası ve meslek hastalıklarının oluşmasını engellemek.
                </li>
                <li>
                  İş sağlığı ve güvenliği konusunda var olan ilkelerimizin
                  benimsenmesi amacıyla, bu doğrultuda personel yapımıza yönelik
                  eğitimler sunmak.
                </li>
                <li>
                  Sıfır iş kazası ve sıfır meslek hastalığı hedefini
                  benimseyerek, gerekli önlemleri almak suretiyle verimliliği
                  artırmaktır.
                </li>
              </Col>
              <Col md={4} sm={12} className="about-policy">
                <img
                  style={{ display: "block", margin: "auto auto 2vh auto" }}
                  src={require("../../assets/images/new-pics/welcome-1.png")}
                  alt=""
                />
                <br />
                <h4 className="text-center">ÇEVRE POLİTİKAMIZ</h4>
                <li>
                  Yürürlükte var olan çevre ile ilgili mevzuat, yasal
                  düzenlemeler ve şartlara uymak.
                </li>
                <li>
                  Canlılara, doğaya ve insanlara duyarlı yenilenebilir enerji
                  sistemleri geliştirmek.
                </li>
                <li>
                  Doğaya saygı bilinciyle, sınırlı olan doğal kaynaklarımızı
                  koruyarak gelecek nesillere iyi bir çevre bırakmak.
                </li>
                <li>
                  Çalışanlar, tedarikçi firmalar ve müşteri portföyümüz de dahil
                  olmak üzere tüm ilgili tarafların çevrekoruma bilincindeo
                  lmasına yönelik çalışmalar yapmak.
                </li>

                <li>
                  Enerji kaynaklı hava, su ve toprak kirliliği gibi insan
                  sağlığı,doğa ve biyoçeşitliliği tehditeden unsurların
                  azaltılmasını sağlamak.
                </li>
              </Col>
            </Row>
          </Container>
        </div>

        <br />
        {/* Sponsors Section */}

        <Brand1 />
        <br />

        <Footer />
      </>
    );
  }
}
export default About;
