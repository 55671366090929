import React, { Component } from "react";
// import Select from "react-select";
import { Link } from "react-router-dom";

// const options = [

//   { value: "tr", label: "TR" },
//   { value: "en", label: "EN" },
//   { value: "rs", label: "RS" },
// ];

class Menu extends Component {
  render() {
    return (
      <>
        <div
          className="collapse navbar-collapse show clearfix"
          id="navbarSupportedContent"
        >
          {/* <ul className="navigation clearfix"></ul> */}
          <ul className="navigation clearfix">
            <li>
              <Link to={"/#"}>
                <img
                  className="logo-class"
                  src={require("../../assets/images/new-pics/pier-logo.png")}
                  alt=""
                />
              </Link>
            </li>
            <li style={{ marginLeft: "25%" }}>
              <Link to={"/"}>ANA SAYFA</Link>
            </li>
            <li>
              <Link to={"/about"}>HAKKIMIZDA</Link>
            </li>
            <li>
              <Link to={"/services-1"}>SERVİSLER</Link>
            </li>
            <li>
              <Link to={"/portfolio-1"}>PROJELER</Link>
            </li>
            <li>
              <Link to={"/contact"}>İLETİŞİM</Link>
            </li>
            <li>
              <a href="#/">PİER MTTP</a>
            </li>
          </ul>

          {/* <ul className="navigation clearfix" >


            </ul> */}
        </div>
      </>
    );
  }
}
export default Menu;
