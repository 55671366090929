import React, { Component } from "react";
import { Link } from "react-router-dom";
import Menu from "./menu";


class Header extends Component {
  state = {
    scrolled: false,
  };

  componentDidMount() {
    var btn = document.querySelector(".mobile-nav-toggler");
    var closeBtn = document.querySelector(".close-btn");
    var body = document.getElementsByTagName("body")[0];

    function addFunc() {
      return body.classList.add("mobile-menu-visible");
    }
    function closeFunc() {
      return body.classList.remove("mobile-menu-visible");
    }

    btn.addEventListener("click", addFunc);
    closeBtn.addEventListener("click", closeFunc);

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    if (window.scrollY > 100) {
      this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false });
    }
  };

  render() {
    const { scrolled } = this.state;
    return (
      <>
        <header
          className={scrolled ? "main-header fixed-header" : "main-header"}
        >
          <div className="header-top">
            <div className="auto-container">
              <div className="inner"></div>
            </div>
          </div>

          <div className="header-upper">
            <div className="auto-container">
              <div className="inner-container">
                <div className="nav-outer clearfix">
                  <div className="mobile-nav-toggler">
                    <span className="icon fal fa-bars"></span>
                  </div>

                  <nav className="main-menu navbar-expand-md navbar-light">
                    <Menu />
                  </nav>
                </div>
              </div>{" "}
            </div>
          </div>

          <div
            className={
              scrolled ? "sticky-header animated slideInDown" : "sticky-header"
            }
          >
            <div className="auto-container clearfix">
              <div className="">
                <nav className="main-menu clearfix">
                  <Menu />
                </nav>
              </div>
            </div>
          </div>

          <div className="mobile-menu">
            <div className="menu-backdrop"></div>
            <div className="close-btn">
              <span className="icon flaticon-cancel"></span>
            </div>

            <nav className="menu-box">
              <div className="nav-logo" >
                <Link to={"/#"} >
                  <img  src="assets/images/pier-logo.jpeg" alt="" title="" />
                </Link>
              </div>
              <div className="menu-outer">
                <Menu />
              </div>
              <div className="social-links">
                <ul className="clearfix">
                  <li>
                    <Link to={"/#"}>
                      <span className="fab fa-twitter"></span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/#"}>
                      <span className="fab fa-facebook-square"></span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/#"}>
                      <span className="fab fa-instagram"></span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/#"}>
                      <span className="fab fa-youtube"></span>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>
      </>
    );
  }
}
export default Header;
