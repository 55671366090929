import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Brand1 from "../element/brand1";
import PortfolioFilter1 from "../element/portfolio-filter1";

const aboutbg = require("./../../assets/images/Proje/Slide.png");

class Portfolio1 extends Component {
  render() {
    return (
      <>
        <Header />


        {/* <!-- Page Banner Section --> */}
        <section className="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div className="bottom-rotten-curve alternate"></div>

          <div className="auto-container">
            <h1>Projelerimiz</h1>
           
          </div>
        </section>
        {/* <!--End Banner Section --> */}

        {/* <!-- Gallery Section --> */}
        <PortfolioFilter1 />

        {/* <!-- Sponsors Section --> */}

        <Brand1  />

        <Footer />
      </>
    );
  }
}
export default Portfolio1;
