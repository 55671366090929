import React from "react";
import "react-animated-slider/build/horizontal.css";
import "../../assets/css/slider.css";
import Carousel from "react-bootstrap/Carousel";

function HomeSlider1() {
  return (
    <Carousel className="home-carousel">
      <Carousel.Item className="img-1">
        <div alt="First slide"></div>
      </Carousel.Item>
      <Carousel.Item className="img-2">
        <div alt="First slide"></div>
      </Carousel.Item>
    </Carousel>
  );
}

export default HomeSlider1;
