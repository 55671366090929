import React, { useState } from "react";
import "react-modal-video/css/modal-video.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <iframe
          src={require("../../assets/katalog.pdf")}
          width={"100%"}
          height={"700"}
          title="abc"
        />
      </Modal.Body>
    </Modal>
  );
}

function PdfPopup() {
  //   const videobg1 = require("./../../assets/images/background/image-2.jpg");
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button
        variant="warning pdf-btn"
        onClick={() => setModalShow(true)}
        size="lg"
      >
        Kataloğu Görmek için Tıklayın
      </Button>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default PdfPopup;
