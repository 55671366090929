import React from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
const aboutbg = require("./../../assets/images/services/222.png");

function Services1() {
  return (
    <>
      <Header />

      {/* <!-- Page Banner Section --> */}
      <section className="page-banner">
        <div
          className="page-banner-bg"
          style={{ backgroundImage: "url(" + aboutbg + ")" }}
        ></div>
        <div className="bottom-rotten-curve alternate"></div>

        <div className="auto-container">
          <h1>SERVİSLER</h1>
        </div>
      </section>

      {/* <!-- News Section --> */}
      <br />
      <br />
      <br />
      <section class="news-section">
        <div
          class="auto-container"
          style={{
            display: "block",
            textAlign: "center",
          }}
        >
          <div class="row">
            {/* <!-- News Block One --> */}
            <div
              class="news-block-one col-lg-4 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1200ms"
            >
              <div class="inner-box">
                <div class="image">
                  <Link to={"/services-details_1"}>
                    <img
                      src={require("../../assets/images/services/12.jpg")}
                      data-src="assets/images/resource/news-1.jpg"
                      alt="/services-details_1"
                    />
                  </Link>
                </div>
                <div class="lower-content">
                  <ul class="post-meta"></ul>
                  <h2
                    style={{
                      fontSize: "16pt",
                    }}
                  >
                    Altyapı Ve Üstyapı Anahtar Teslim Çözümler
                  </h2>
                  <div class="text">
                    Yüksek ve alçak gerilim elektrik taahhüt projeleri, GES
                    kurulum hizmetlerinin tamamına hitap etmektedir.
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- News Block One --> */}
            <div
              class="news-block-one col-lg-4 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1200ms"
            >
              <div class="inner-box">
                <div class="image">
                  <Link to={"/services-details_2"}>
                    <img
                      src={require("../../assets/images/services/4.png")}
                      data-src="assets/images/resource/news-2.jpg"
                      alt="/services-details_2"
                    />
                  </Link>
                </div>
                <div class="lower-content">
                  <ul class="post-meta"></ul>

                  <h2
                    style={{
                      fontSize: "16pt",
                    }}
                  >
                    Pier MTTP - Enerji Verimliliği Yönetim Platformu
                  </h2>

                  <div class="text">
                    Tesisin enerji maliyetinin azaltılması, operasyonel
                    verimliliğin arttırılması ve etüt raporlarını oluşturur.
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- News Block One --> */}
            <div
              class="news-block-one col-lg-4 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1200ms"
            >
              <div class="inner-box">
                <div class="image">
                  <Link to={"/services-details_3"}>
                    <img
                      src={require("../../assets/images/services/10.jpg")}
                      alt="/services-details_3"
                    />
                  </Link>
                </div>
                <div class="lower-content">
                  <ul class="post-meta"></ul>

                  <h2
                    style={{
                      fontSize: "16pt",
                    }}
                  >
                    Proje ve Mühendislik Müşavirlik Hizmetleri
                  </h2>

                  <div class="text">
                    Pier Enerji SMM kayıtlı proje müşavirlik firması olarak;
                    alanında yetkin ve uzman mühendis kadromuzla hizmet verir.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <br />
      <br />
      <Footer />
    </>
  );
}
export default Services1;
