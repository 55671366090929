import React from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import HomeSlider1 from "./../element/home-slider1";
import News from "../element/news";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Button from "react-bootstrap/Button";

// import '../../assets/css/color.css';
import VideoPopup1 from "./../element/video-popup1";
// import WhatWeDo from "../element/WhatWeDo";
// const pierbg = require("./../../assets/images/new-pics/pier-enerji-bg.png");

function Index() {
  return (
    <>
      <Header />

      <HomeSlider1 />
      {/* <!-- Welcome Setion --> */}
      <section className="welcome-section pt-0 welcome-bg">
        <div className="auto-container">
          <div className="wrapper-box ">
            <div className="row m-0">
              {/* <!-- Welcome Block One --> */}
              <div
                className="welcome-block-one col-lg-4 wow fadeInUp welcome-1"
                data-wow-delay="200ms"
                data-wow-duration="1200ms"
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <img
                      src={require("../../assets/images/new-pics/welcome-1.png")}
                      alt=""
                    />
                  </div>
                  <h2>Kalite Politikamız</h2>
                  <div className="text" style={{ fontSize: "12pt" }}>
                    Tüm faaliyetlerimiz yürürlükte varolan yasa, yönetmelik ve
                    mevzuatlara uygun olarak; ticari ahlak anlayışımız iş
                    kazancının önüne geçmeden, yüksek kaliteli ürün ve
                    hizmetlerle müşterilerimizin memnuniyetini arttırmayı
                    taahhüt ediyoruz.
                  </div>
                </div>
              </div>

              {/* <!-- Welcome Block One --> */}
              <div
                className="welcome-block-one col-lg-4 wow fadeInUp welcome-1"
                data-wow-delay="200ms"
                data-wow-duration="1200ms"
              >
                <div className="inner-box ">
                  <div className="icon-box ">
                    <img
                      src={require("../../assets/images/new-pics/welcome-2.png")}
                      alt=""
                    />
                  </div>
                  <h2>İş Sağlığı ve Güvenliği Politikamız</h2>
                  <div className="text" style={{ fontSize: "12pt" }}>
                    İş sağlığı ve güvenliği konusunda bilinç oluşturmak ve bunu
                    yaygınlaşabilir hale getirmek amacıyla personel yapımıza
                    yönelik eğitimler sunmakta; sıfır iş kazası ve sıfır meslek
                    hastalığını ilkelendiniriz.
                  </div>
                </div>
              </div>
              {/* <!-- Welcome Block One --> */}
              <div
                className="welcome-block-one col-lg-4 wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1200ms"
              >
                <div className="inner-box  ">
                  <div className="icon-box ">
                    <img
                      src={require("../../assets/images/new-pics/welcome-3.png")}
                      alt=""
                    />
                  </div>
                  <h2>Çevre Politikamız</h2>
                  <div className="text " style={{ fontSize: "12pt" }}>
                    Canlılara, doğaya ve insanlara duyarlı yenilebilir enerji
                    sistemleri geliştirmekle beraber, atıkların en aza
                    indirgenmesi, mevcut doğal kaynakların korunması ve geri
                    kazanılmasını hedefleriz
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <News />
      {/* <!-- About Section --> */}
      <div>
        <section className="about-section index-about-bg">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="image-wrapper">
                  <div className="image-one">
                    <img
                      src={require("../../assets/images/new-pics/pier-enerji-bg1.png")}
                      alt=""
                    />
                  </div>
                  <div
                    className="image-two wow fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1200ms"
                  >
                    {/* <div className="caption">Açıklama</div> */}
                    <div className="image-outer">
                      <img
                        src={require("../../assets/images/new-pics/pier-enerji-bg2.png")}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="content-box">
                  <div className="sec-title">
                    <h2 style={{ color: "#ef742a", fontSize: "80pt" }}>
                      {" "}
                      Pier Enerji
                    </h2>
                    <div className="text">
                      Sunmuş olduğu kaliteli hizmetanlayışı, inovatif çözümler
                      prensibi, verimlilik ve etkinlik yapısı ile projelere
                      değer katmaktadır.
                      <br />
                      <br />
                      Deneyimin profesyonel iş standartları yaklaşımı ilkesiyle
                      beraber; “Enerji, Proje, Taahhüt, Danışmanlık” gibi enerji
                      sektörünün birçok alanında faaliyetlerimiz devam
                      etmektedir. Bu kapsamda; müşteri odaklı, kaliteli,
                      güvenilir tesisler ve sistemler üretmek, her projede
                      detaycı bir yaklaşım,başarılı sonuçlara ulaşma hedefi ile
                      sizin için üst düzey hizmet vermekteyiz.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <!-- Video Section --> */}
      <VideoPopup1 />
      {/* <!-- Services Section --> */}
      <section className="services-section ">
        <div className="auto-container ">
          <div className="wrapper-box ">
            <div className="left-column left-border">
              <div className="services-content ">
                <div className="sec-title light ">
                  <h2>Hizmetlerimiz</h2>
                </div>
                <div
                  className="icon-box wow fadeInUp "
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div className="icon">
                    <img
                      src={require("../../assets/images/new-pics/n1.png")}
                      alt=""
                    />
                  </div>
                  <h2>Elektrik,Enerji ve Müteahhitlik Hizmetleri</h2>
                  <div className="text">
                    Pier Enerji ve Sanayi Ltd. Şti. tarafından gerçekleştirilen
                    anahtar teslim enerji taahhüt projeleri, üst yapı elektrik
                    işleri, yüksek alçak gerilim elektrik taahhüt projeleri, GES
                    kurulum hizmetlerinin tamamını içerisine almaktadır.{" "}
                  </div>
                </div>
                <div
                  className="icon-box wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div className="icon">
                    <img
                      src={require("../../assets/images/new-pics/n2.png")}
                      alt=""
                    />
                  </div>
                  <h2>Proje ve Ofis Hizmeti</h2>
                  <div className="text">
                    Pier ENERJİ SMM Kayıtlı Proje Müşavirlik Firmasıdır.
                    Alanında Yetkin ve Uzman Mühendis Kadromuz Projelendirme
                    Süreçlerinde, Teknik Değerlendirmelerin Yanı Sıra Finansal
                    Fayda Maliyet Analizleri ile Optimum Verimlilik Hedefler.{" "}
                  </div>
                </div>
                <div
                  className="icon-box wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div className="icon">
                    <img
                      src={require("../../assets/images/new-pics/n3.png")}
                      alt=""
                    />
                  </div>
                  <h2>Endüstriyel çözümler</h2>
                  <div className="text">
                    Endüstriyel Tesislerde Güçlü Proje Yönetimi ve Verimli
                    Personel Yapısı İle Kazançlı İş Gücü Sağlıyoruz. Finansal ve
                    İşgücü Verimliliği Yüksek Çalışma Sistemimiz İle Kısa Sürede
                    Yatırımlarınız Kazanca Dönüştürüyoruz.{" "}
                  </div>
                </div>
              </div>
            </div>

            {/* sağ */}
            <div className="left-2-column">
              <div className="services-content">
                <div className="sec-title light ">
                  <br />
                  <br />
                  <br />
                </div>
                <div
                  className="icon-box wow fadeInUp "
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div className="icon">
                    <img
                      src={require("../../assets/images/new-pics/n4.png")}
                      alt=""
                    />
                  </div>
                  <h2>PierTek AR-GE HİZMETLERİ</h2>
                  <div className="text">
                    PierTek ARGE Hizmetleri, IoT teknolojisi aracılığıyla sistem
                    entegrasyon alt yapısı sağlayıcısıdır. Enerji izleme ve
                    ticareti platformlarının sistem alt yapısına teknolojik ürün
                    araştırma ve geliştirme çalışmalarını gerçekleştiren Pier
                    Enerji teknik hizmet birimidir.{" "}
                  </div>
                </div>
                <div
                  className="icon-box wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div className="icon">
                    <img
                      src={require("../../assets/images/new-pics/n5.png")}
                      alt=""
                    />
                  </div>
                  <h2>Zayıf Akım Sistemleri</h2>
                  <div className="text">
                    Güvenlik, Data ve İzleme Sistemleri ile Projelere Değer
                    Katıyoruz. Yangın İhbar ve Uyarı, Acil Anons ve Yönlendirme,
                    CCTV, Data, IP/Analog Telefon ve Headend TV Başta Olmak Üzre
                    15 Zayıf Akım Sistemine Profesyonel Yaklaşımlar Sunuyoruz.{" "}
                  </div>
                </div>
                <div
                  className="icon-box wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div className="icon">
                    <img
                      src={require("../../assets/images/new-pics/n6.png")}
                      alt=""
                    />
                  </div>
                  <h2>Altyapı Elektrik Taahhüt</h2>
                  <div className="text">
                    Elektrik İletim ve Dağıtım Sistemleri Kapsamında İndirici
                    Merkez, İletim Hattı, Trafo Merkezi ve Dağıtım Hattı
                    Tesisleri İle Uçtan Uca Anahtar Teslim Projeler Hayata
                    Geçiriyoruz.{" "}
                  </div>
                </div>
              </div>
            </div>

            {/* ffdfd */}
            <div className="right-column">{/* <!-- Fact Counter --> */}</div>
            <div className="fact-counter">
              <div className="row">
                {/* <!--Column--> */}
                <div className="column counter-column col-md-2 ">
                  <div
                    className="inner wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="content">
                      <div className="icon">
                        <img
                          style={{
                            display: "block",
                            margin: "auto",
                            marginLeft: "30%",
                          }}
                          width={"83px"}
                          src={require("../../assets/images/new-pics/cnt-1.png")}
                          alt=""
                        />
                      </div>
                      <div className="count-outer count-box">
                        <CountUp end={18} redraw={true}>
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                              <div className="count-text">
                                {" "}
                                <span ref={countUpRef} />
                                <p style={{ display: "block", margin: "auto" }}>
                                  Adet
                                </p>
                              </div>
                            </VisibilitySensor>
                          )}
                        </CountUp>
                      </div>
                      <div
                        className="counter-title"
                        style={{ margin: "auto", display: "block" }}
                      >
                        İmza Atılan Proje
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!--Column--> */}
                <div className="column counter-column col-md-2">
                  <div
                    className="inner wow fadeInLeft"
                    data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="content">
                      <div className="icon">
                        <img
                          style={{
                            display: "block",
                            margin: "auto",
                            marginLeft: "30%",
                          }}
                          width={"83px"}
                          src={require("../../assets/images/new-pics/cnt-2.png")}
                          alt=""
                        />
                      </div>
                      <div className="count-outer count-box">
                        <CountUp end={146} redraw={true}>
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                              <div className="count-text">
                                {" "}
                                <span ref={countUpRef} />
                                <p>Megawatt</p>
                              </div>
                            </VisibilitySensor>
                          )}
                        </CountUp>
                      </div>
                      <div className="counter-title">Kurulu Güç</div>
                    </div>
                  </div>
                </div>

                {/* <!--Column--> */}
                <div className="column counter-column col-md-2">
                  <div
                    className="inner wow fadeInLeft"
                    data-wow-delay="600ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="content">
                      <div className="icon">
                        <img
                          style={{ display: "block", margin: "auto" }}
                          width={"83px"}
                          src={require("../../assets/images/new-pics/cnt-3.png")}
                          alt=""
                        />
                      </div>
                      <div className="count-outer count-box">
                        <CountUp end={7275} redraw={true}>
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                              <div className="count-text">
                                {" "}
                                <span ref={countUpRef} />
                                <p>Kilometre</p>
                              </div>
                            </VisibilitySensor>
                          )}
                        </CountUp>
                      </div>
                      <div className="counter-title">Çekilen Kablo</div>
                    </div>
                  </div>
                </div>
                <div className="column counter-column col-md-2">
                  <div
                    className="inner wow fadeInLeft"
                    data-wow-delay="600ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="content">
                      <div className="icon">
                        <img
                          style={{ display: "block", margin: "auto" }}
                          width={"83px"}
                          src={require("../../assets/images/new-pics/cnt-4.png")}
                          alt=""
                        />
                      </div>
                      <div className="count-outer count-box">
                        <CountUp end={26} redraw={true}>
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                              <div className="count-text">
                                {" "}
                                <span ref={countUpRef} />
                                <p>Adet</p>
                              </div>
                            </VisibilitySensor>
                          )}
                        </CountUp>
                      </div>
                      <div className="counter-title">Sosyal Tesis</div>
                    </div>
                  </div>
                </div>
                <div className="column counter-column col-md-2">
                  <div
                    className="inner wow fadeInLeft"
                    data-wow-delay="9000ms"
                    data-wow-duration="3000ms"
                  >
                    <div className="content">
                      <div className="icon">
                        <img
                          style={{ display: "block", margin: "auto" }}
                          width={"83px"}
                          src={require("../../assets/images/new-pics/cnt-5.png")}
                          alt=""
                        />
                      </div>
                      <div className="count-outer count-box">
                        <CountUp end={4817} redraw={true}>
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                              <div className="count-text">
                                {" "}
                                <span ref={countUpRef} />
                                <p>Adet</p>
                              </div>
                            </VisibilitySensor>
                          )}
                        </CountUp>
                      </div>
                      <div className="counter-title">Konut</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
      {/* <WhatWeDo /> */}
      <Container fluid className="about-index-container">
        <Row style={{ margin: "90px 90px" }}>
          <Col md={3}>
            <img
              width={"50%"}
              style={{ display: "block", margin: "10vh 2vh auto auto" }}
              src={require("../../assets/images/new-pics/pier-about-logo.png")}
              alt=""
            />
          </Col>
          <Col className="about-stick" md={1} style={{ height: "25vh" }}>
            <img
              style={{ display: "block", margin: "auto", height: "35vh" }}
              src={require("../../assets/images/new-pics/about-layer.png")}
              alt=""
            />
          </Col>
          <Col className="roboto" md={7}>
            <h2 style={{ color: "#5c5c5c", fontSize: "34pt" }}>Hakkımızda</h2>
            <p style={{ fontSize: "13pt", textAlign: "justify" }}>
              Temelleri 2016 yılında atılan Pier Enerji, elektrik üretiminden
              son kullanıcıya uzanan elektrik değer zincirinin her noktasında
              mühendislik hizmeti vermektedir. Sunmuş olduğu kaliteli hizmet
              anlayışını, piyasa tecrübesi ve uzman kadrosunun maksimum
              verimlilik ilkesinden almaktadır. Elektrik taahhüt hizmetlerinde
              kaliteli bakış açısıyla proje ve teknik gereksinimleri yerine
              getirmeyi şiar edinen şirketimiz; üst yapıda otel, ticaret
              merkezi, toplu konut, okul gibi birçok projede anahtar teslim
              çözümler sunarken, altyapı ve endüstriyel tesislerde dizayn ofis,
              etkin personel verimliliği ve proje yönetimi konuları ile öne
              çıkmaktadır. Şirketimiz sunmuş olduğu kaliteli hizmet anlayışı,
              inovatif çözümler prensibi,verimlilik ve etkinlik yapısı ile
              projelere değer katmaktadır. hizmetanlayışı, inovatif çözümler
              prensibi, verimlilik ve etkinlik yapısı ile projelere değer
              katmaktadır.
            </p>
            <Button
              variant="secondary"
              style={{ backgroundColor: "#888888", float: "right" }}
            >
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to={"/about"}
              >
                Daha Fazla
              </Link>
            </Button>{" "}
          </Col>
        </Row>
      </Container>
      <br />
      <Footer />
    </>
  );
}
export default Index;
