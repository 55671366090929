import React, { useState } from "react";
import { Link } from "react-router-dom";

const images = [
  {
    id: 1,
    name: "Ankara / Sincan",
    text: "502 Konut Yaşam Kompleksi",
    insurance: true,
    imgUrl: require("../../assets/images/Proje/Proje-01.png"),
  },
  {
    id: 2,
    name: "Ankara / Sincan",
    text: "60 Derslikli Lise",
    insurance: true,
    imgUrl: require("../../assets/images/Proje/Proje-02.png"),
  },
  {
    id: 3,
    name: "Niğde / Merkez",
    text: "720 Konut Yaşam Kompleksi",
    insurance: true,
    imgUrl: require("../../assets/images/Proje/Proje-03.png"),
  },
  {
    id: 4,
    name: "Ankara / Keçiören",
    text: "Ova Time Sitesi",
    insurance: true,
    imgUrl: require("../../assets/images/Proje/Proje-04.png"),
  },
  {
    id: 5,
    name: "Ankara / Sincan",
    text: "541 Konut Yaşam Kompleksi",
    insurance: true,
    imgUrl: require("../../assets/images/Proje/Proje-05.png"),
  },
  {
    id: 6,
    name: "İstanbul / Esenler",
    text: "535 Konut Yaşam Kompleksi",
    insurance: true,
    imgUrl: require("../../assets/images/Proje/Proje-06.png"),
  },
  {
    id: 7,
    name: "Konya / Karapınar",
    text: "609 Konut Yaşam Kompleksi",
    banking: true,
    imgUrl: require("../../assets/images/Proje/Proje-07.png"),
  },
  {
    id: 8,
    name: "Karaman / Merkez",
    text: "820 Konut Yaşam Kompleksi",
    banking: true,
    imgUrl: require("../../assets/images/Proje/Proje-08.png"),
  },
  {
    id: 9,
    name: "Karaman / Merkez",
    text: "320 Konut Yaşam Kompleksi",
    banking: true,
    imgUrl: require("../../assets/images/Proje/Proje-09.png"),
  },
  {
    id: 10,
    name: "Karaman / Akçaşehir",
    text: "142 Konut Yaşam Kompleksi",
    banking: true,
    imgUrl: require("../../assets/images/Proje/Proje-10.png"),
  },
  {
    id: 11,
    name: "Kırıkkale / Keskin",
    text: "124 Konut Yaşam Kompleksi",
    banking: true,
    imgUrl: require("../../assets/images/Proje/Proje-11.png"),
  },
  {
    id: 12,
    name: "Eskişehir / Sivrihisar",
    text: "209 Konut Yaşam Kompleksi",
    banking: true,
    imgUrl: require("../../assets/images/Proje/Proje-12.png"),
  },
  {
    id: 13,
    name: "Bursa / Harmancık",
    text: "84 Konut Yaşam Kompleksi",
    banking: true,
    imgUrl: require("../../assets/images/Proje/Proje-13.png"),
  },
  {
    id: 14,
    name: "Uşak / Karaağaç",
    text: "Konut Yaşam Kompleksi",
    banking: true,
    imgUrl: require("../../assets/images/Proje/Proje-13.png"),
  },
  {
    id: 15,
    name: "Kayseri / Kocasinan",
    text: "572 Konut Yaşam Kompleksi",
    banking: true,
    imgUrl: require("../../assets/images/Proje/Proje-13.png"),
  },
  {
    id: 16,
    name: "Uşak / Eşme",
    text: "158 Konut Yaşam Kompleksi",
    banking: true,
    imgUrl: require("../../assets/images/Proje/Proje-13.png"),
  },
];

const FILTER_DEFS = {
  banking: (image) => image.banking,
  insurance: (image) => image.insurance,
  NONE: (image) => image,
};

const PortfolioFilter1 = (props) => {
  const [state, setState] = useState({
    list: images,
    filterKey: "NONE",
  });

  const { list, filterKey } = state;
  const filteredList = list.filter(FILTER_DEFS[filterKey]);
  const [filter1, setFilter1] = useState("");
  const [filter2, setFilter2] = useState("");
  const [filter3, setFilter3] = useState("");
  return (
    <>
      <section className="gallery-section"style={{  marginBottom: "90px" }}>
        <div className="sortable-masonry">
          <div className="auto-container">
            <div className="row m-0 justify-content-md-between align-items-center">
              <div className="sec-title" style={{ marginTop: "6rem" }}>
                <div className="sub-title">
                  <p style={{ fontSize: "22pt" }}>
                    Projeler
                  </p>
                </div>
                <h2>Yaptığımız Projeler</h2>
              </div>
              {/* <!--Filter--> */}
              <div
                className="filters text-center wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1200ms"
              >
                <ul className="filter-tabs filter-btns">
                    <li


                      className={`${filter1} filter`}
                      onClick={() => {
                        setState({ ...state, filterKey: "NONE" });
                        setFilter1("active");
                        setFilter2("");
                        setFilter3("");
                      }}

                    >
                      <span>Bütün Projeler</span>
                    </li>

                    <li

                      className={`${filter2} filter`}
                      onClick={() => {
                        setState({ ...state, filterKey: "insurance" });
                        setFilter2("active");
                        setFilter1("");
                        setFilter3("");
                      }}



                    >
                      <span>Tamamlanmış Projeler</span>
                    </li>

                    <li


                      className={`${filter3} filter`}
                      onClick={() => {
                        setState({ ...state, filterKey: "banking" });
                        setFilter3("active");
                        setFilter1("");
                        setFilter2("");
                      }}

                    >
                      <span>Devameden Projeler</span>
                    </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="auto-container">
            <div className="items-container row">
              {filteredList.map((image) => (
                <div
                  className="col-lg-3 col-md-6 gallery-block-one "
                  key={image.id}
                >
                  <div className="inner-box">
                    <div className="image">
                      <img src={image.imgUrl} alt="" />
                    </div>
                    <div className="caption-title">
                      <h5>{image.name}</h5>
                      <h3>
                        <Link to={`proje${image.id}`}>{image.text}</Link>
                      </h3>
                      <div className="view-project">
                        <Link
                          data-fancybox="example gallery"
                          to={`proje${image.id}`}
                          className="zoom-btn"
                        >
                          <span>+</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PortfolioFilter1;
