import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import "react-circular-progressbar/dist/styles.css";

const alarmsystem = require("./../../assets/images/project/alarmsystem.png");
const altyapi = require("./../../assets/images/project/altyapi.png");
const aydinlatma = require("./../../assets/images/project/aydinlatma.png");
const elektriktesisat = require("./../../assets/images/project/elektriktesisat.png");
const enerjinakil = require("./../../assets/images/project/enerjinakil.png");
const faradaykafesi = require("./../../assets/images/project/faradaykafesi.png");
const guvenlikkamera = require("./../../assets/images/project/guvenlikkamera.png");
const ipanalogphone = require("./../../assets/images/project/ipanalogphone.png");
const network = require("./../../assets/images/project/Network.png");
const ortagerilim = require("./../../assets/images/project/ortagerilim.png");
const kartligiris = require("./../../assets/images/project/kartligiris.png");
const topraklamasistem = require("./../../assets/images/project/topraklamasistem.png");
const traforesisler = require("./../../assets/images/project/traforesisler.png");
const turnike = require("./../../assets/images/project/turnike.png");
const ustyapi = require("./../../assets/images/project/ustyapi.png");
const yanginihbar = require("./../../assets/images/project/yanginihbar.png");
const yapisalkablolama = require("./../../assets/images/project/yapisalkablolama.png");
const paratonersistem = require("./../../assets/images/project/paratonersistem.png");

function ServicesDetails() {
  const [img, setimg] = useState(altyapi);
  return (
    <>
      <Header />

      {/* <!-- Sidebar Page Container --> */}
      <div class="sidebar-page-container">
        <div class="auto-container">
          <div class="row">
            <div class="col-lg-4">
              <aside class="sidebar">
                {/* <!-- Category Widget --> */}
                <div class="sidebar-widget-two categories-widget-two">
                  <div class="widget-content">
                    <ul>
                      <li
                        onMouseEnter={() => {
                          setimg(altyapi);
                        }}
                      >
                        <Link>
                          Altyapı Uygulamaları <i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(aydinlatma);
                        }}
                      >
                        <Link>
                          Aydınlatma Çözümleri <i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(elektriktesisat);
                        }}
                      >
                        <Link>
                          Elektrik Tesisat Uygulamaları <i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(enerjinakil);
                        }}
                      >
                        <Link>
                          Enerji Nakil Hatı<i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(ortagerilim);
                        }}
                      >
                        <Link>
                          Orta Gerilim Uygulamaları<i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(ustyapi);
                        }}
                      >
                        <Link>
                          Üstyapı AG Uygulamaları <i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(traforesisler);
                        }}
                      >
                        <Link>
                          Trafo Tesisleri <i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(alarmsystem);
                        }}
                      >
                        <Link>
                          Alarm Sistemleri<i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(guvenlikkamera);
                        }}
                      >
                        <Link>
                          Güvenlik Kamerası Çözümleri <i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(ipanalogphone);
                        }}
                      >
                        <Link>
                          IP ve Analog Telefon Santrali <i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(kartligiris);
                        }}
                      >
                        <Link>
                          Kartlı Geçiş Sistemleri <i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(network);
                        }}
                      >
                        <Link>
                          Network ve Kablosuz Haberleşme Çözümleri<i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(yanginihbar);
                        }}
                      >
                        <Link>
                          Yangın Algılama ve İhbar Sistemleri <i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(yapisalkablolama);
                        }}
                      >
                        <Link>
                          Yapısal Kablolama Çözümleri<i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(turnike);
                        }}
                      >
                        <Link>
                          Turnike ve PDKS sistemleri <i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(faradaykafesi);
                        }}
                      >
                        <Link>
                          Faraday Kafesi Uygulamaları <i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(paratonersistem);
                        }}
                      >
                        <Link>
                          Paratoner Sistemleri <i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(paratonersistem);
                        }}
                      >
                        <Link>
                          Paratoner Ölçümü <i></i>
                        </Link>
                      </li>
                      <li
                        onMouseEnter={() => {
                          setimg(topraklamasistem);
                        }}
                      >
                        <Link>
                          Topraklama Sistemleri <i></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </aside>
            </div>
            <div class="col-lg-8">
              <div class="services-details pl-lg-5">
                <div class="image-box">
                  <img src={img} alt=""/>
                </div>
                <div class="content">
                  <div class="sec-title">
                    <div class="sub-title">
                      Altyapı ve Üstyapı Elektrik Tesis, Otomasyon ve Zayıf Akım
                      Sistemlerinde
                      <br />
                      Anahtar Teslim Çözümler Sunmaktayız.{" "}
                    </div>
                    <h2>
                      Elektrik, Enerji ve Müteahhitlik Hizmetleri <br />{" "}
                    </h2>
                  </div>
                  <div class="text">
                    Endüstriyel tesislerde güçlü proje yönetimi ve verimli
                    personel yapısıyla kazançlı iş gücü sağlıyoruz. Finansal ve
                    iş gücü verimliliği yüksek çalışma sistemimiz ile beraber
                    kısa sürede yatırımlarınızı kazanca dönüştürüyoruz.
                  </div>
                  <div class="text">
                    Güvenlik, data ve izleme sistemleri ile beraber projelere
                    değer katıyoruz. Yangın ihbar ve uyarı, acil anons ve
                    yönlendirme, CCTV, data, IP/Analog telefon ve headend TV
                    başta olmak üzere 15 zayıf akım sistemine profesyonel
                    yaklaşımlar sunuyoruz.
                  </div>
                  <div class="text">
                    Fabrika otomasyonları, akıllı bina otomasyonları,
                    endüstriyel otomasyon sistem ve diğer otomasyon
                    sistemlerinin imalatları ve anahtar teslim devreye alınma
                    işlemlerini hizmetinize sunuyoruz.
                  </div>

                  <blockquote>
                    <div class="text">
                      Pier Enerji ve Sanayi Ltd. Şti. tarafından
                      gerçekleştirilen anahtar teslim enerji taahhüt projeleri,
                      altyapı-üstyapı elektrik işleri, yüksek alçak gerilim
                      elektrik taahhüt projeleri, GES kurulum hizmetlerinin
                      tamamına hitap etmektedir.
                    </div>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default ServicesDetails;
