import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import emailjs from "emailjs-com";

function Contact() {
  const navigate = useNavigate();
  const form = useRef();
  // const [value, setValue] = useState();
  // const [check, setCheck] = useState(false);
  // const [recaptcha, setRecaptcha] = useState(false);
  const [message, setMessage] = useState("");
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // const checked = () => {
  //   setCheck(!check);
  // };
  // const changerecapthca = () => {
  //   setRecaptcha(!recaptcha);
  // };
  const goHome = async () => {
    setMessage("Mesajınız Gönderildi Ana sayfaya Yönlendiriliyorsunuz.");
    await delay(3000);
    navigate("/");
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_h7aadkt",
        "template-1",
        form.current,
        "Z5cX4vCcV8Y0rWzbU",
        goHome()
      )
      .then(
        (result) => {
          console.log(result);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <Header />

      {/* <!--Search Popup--> */}
      <div id="search-popup" className="search-popup">
        <div className="close-search theme-btn">
          <span className="flaticon-cancel"></span>
        </div>
        <div className="popup-inner">
          <div className="overlay-layer"></div>
          <div className="search-form">
            <form>
              <div className="form-group">
                <fieldset>
                  <input
                    type="search"
                    className="form-control"
                    name="search-input"
                    value=""
                    placeholder="Search Here"
                    required
                  />
                  <input
                    type="submit"
                    value="Search Now!"
                    className="theme-btn"
                  />
                </fieldset>
              </div>
            </form>
            <br />
            <h3>Recent Search Keywords</h3>
            <ul className="recent-searches">
              <li>
                <Link to={"/#"}>Finance</Link>
              </li>
              <li>
                <Link to={"/#"}>Idea</Link>
              </li>
              <li>
                <Link to={"/#"}>Service</Link>
              </li>
              <li>
                <Link to={"/#"}>Growth</Link>
              </li>
              <li>
                <Link to={"/#"}>Plan</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* <!-- Page Banner Section --> */}
      <section className="page-banner1  ">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3011.311812069258!2d29.08529001566194!3d40.99654792809509!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac605c0a2c18d%3A0x39561dbd20c27291!2zUMSwRVIgRU5FUkrEsA!5e0!3m2!1str!2str!4v1665220253907!5m2!1str!2str"
          width="100%"
          height="568vh"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="map"
        ></iframe>
      </section>

      {/* <!-- Map Section --> */}
      {/* <!-- Map Section --> */}
      {/* <section className="map-section">
        <div className="map-column">
          <img src={require("./../../assets/images/contact-slide.png")} />
        </div>
      </section> */}

      {/* <!-- Contact Section Two --> */}
      <section className="contact-section-two">
        <div className="auto-container">
          <div className="contact-info-area">
            <div className="contact-info">
              <div className="row">
                <div className="info-column col-lg-4">
                  <div style={{ marginLeft: "2rem" }} className="icon-box">
                    <div className="icon">
                      <span className="flaticon-email-6"></span>
                    </div>

                    <h3 style={{ marginLeft: "6%" }}>Email Adresi</h3>
                    <ul style={{ marginRight: "2%" }}>
                      <li>
                        <Link to={"/mailto:info@pierenerji.com"}>
                          info@pierenerji.com
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="info-column col-lg-4">
                  <div style={{ marginLeft: "2rem" }} className="icon-box">
                    <div className="icon">
                      <span className="flaticon-call-1"></span>
                    </div>
                    <div style={{ marginLeft: "15%" }}>
                      {" "}
                      <h3>Telefon Numarası</h3>
                      <ul style={{ marginRight: "10%" }}>
                        <li>
                          <Link to={"/tel:+90 216 969 3437"}>
                            +90 216 969 3437
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="info-column col-lg-4">
                  <div style={{ marginLeft: "2rem" }} className="icon-box">
                    <div className="icon">
                      <span className="flaticon-location"></span>
                    </div>
                    <div style={{ marginLeft: "3%" }}>
                      <h3>Adres</h3>
                      <ul>
                        <li>
                          Esatpaşa Mah Ziyapaşa Cad. No:6 Kat:1 Daire:1
                          Ataşehir/İstanbul
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-form-area">
            <div className="sec-title text-center">
              <h2>İletişim Formu</h2>
            </div>
            {/* <!-- Contact Form--> */}
            <div className="contact-form">
              <form ref={form} onSubmit={sendEmail} id="contact-form">
                <div className="row clearfix">
                  <div className="col-md-6 form-group">
                    <input
                      style={{
                        borderRadius: "1%",
                        backgroundColor: "white",
                        border: "2px solid #ccc",
                      }}
                      type="text"
                      name="user_name"
                      id="name"
                      placeholder="Adınız Soyadınız"
                      required
                    />
                  </div>

                  <div className="col-md-6 form-group">
                    <input
                      style={{
                        borderRadius: "1%",
                        backgroundColor: "white",
                        border: "2px solid #ccc",
                      }}
                      type="text"
                      name="user_konu"
                      id="email"
                      placeholder="Konu"
                      required=""
                    />
                  </div>

                  <div className="col-md-6 form-group">
                    <input
                      style={{
                        borderRadius: "1%",
                        backgroundColor: "white",
                        border: "2px solid #ccc",
                      }}
                      type="email"
                      name="user_mail"
                      id="name"
                      placeholder="E-Posta Adresiniz"
                      required
                    />
                  </div>

                  <div className="col-md-6 form-group">
                    <input
                      style={{
                        borderRadius: "1%",
                        backgroundColor: "white",
                        border: "2px solid #ccc",
                      }}
                      type="text"
                      name="user_phone"
                      id="email"
                      placeholder="Telefon Numaranız"
                      required=""
                    />
                  </div>
                  <div className="col-md-12 form-group">
                    <textarea
                      style={{
                        borderRadius: "1%",
                        backgroundColor: "white",
                        border: "2px solid #ccc",
                      }}
                      name="message"
                      id="message"
                      placeholder="Mesajınız"
                    ></textarea>
                    <i className="fas fa-edit"></i>
                  </div>

                  <div
                    className="col-md-12 form-group"
                    style={{ textAlign: "center" }}
                  >
                    <button className="theme-btn btn-style-one" type="submit">
                      <span className="btn-title">Gönder</span>
                    </button>
                  </div>
                  {message}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Contact;
