import React from "react";
import { Link } from "react-router-dom";
function news() {
  const newsbg1 = require("./../../assets/images/new-pics/haber-bg.png");
  return (
    <section className="news-section">
      <div
        className="sec-bg"
        style={{ backgroundImage: "url(" + newsbg1 + ")"}}
      ></div>
      <div className="auto-container">
        <div className="sec-title text-center light" style={{ marginTop: "4rem" }}>
          <div className="sub-title big-fontsize">
            <p style={{ fontSize: "22pt", marginTop: "0rem" }}>
              Pier Enerji
            </p>
          </div>
          <h2 style={{ marginTop: "-1rem" }}>Bizden Haberler</h2>
        </div>
        <div className="row">
          {/* <!-- News Block One --> */}
          <div
            className="news-block-one col-lg-4 wow fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1200ms" 
            style={{marginTop: "0rem" }}
          >
            <div className="inner-box">
              <div className="image">
                <Link to={"/#"}>
                  <img
                    src={require("../../assets/images/new-pics/haber-1.png")}
                    alt=""
                  />
                </Link>
              </div>
              <div className="lower-content">
                <br />
                <h3 className="form-center">
                  Euf Fuarındayız <br />
                </h3>
                <br />
                <div className="text">
                  Pier Enerji, Enerjisini Üreten Fabrikalar Fuarında Enerji
                  Ticaret Platformu Sergiledi. Yazılımı ve Sistem Mimarisi Pier
                  Enerji Mühendisleri Tarafından Tasarlanan ETS Yoğun İlgi
                  Gördü.
                </div>
              </div>
            </div>
          </div>
          {/* <!-- News Block One --> */}
          <div
            className="news-block-one col-lg-4 wow fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1200ms"
            style={{marginTop: "0rem" }}
            
          >
            <div className="inner-box">
              <div className="image">
                <img
                  src={require("../../assets/images/new-pics/haber-2.png")}
                  alt=""
                />
              </div>
              <div className="lower-content">
                <br />
                <h3 className="form-center">Pier ETS Sunumu</h3>
                <br />
                <div className="text">
                  Pier Enerji Genel Müdürü Rıdvan GÜLTEKİN, "Enerji İzleme
                  Konusunda Yeni Yöntemler ve Dijitalleşme" Konulu Panelde Yeni
                  Nesil Enerji Ticaret Plarformu Pier ETS' yi Anlattı.
                </div>
              </div>
            </div>
          </div>
          {/* <!-- News Block One --> */}
          <div
            className="news-block-one col-lg-4 wow fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1200ms"
          >
            <div className="inner-box">
              <div className="image">
                <Link to={"/#"}>
                  <img
                    src={require("../../assets/images/new-pics/haber-3.png")}
                    alt=""
                  />
                </Link>
              </div>
              <div className="lower-content">
                <br />
                <h3 className="form-center">
                  Karaman'da 1282 Konut <br />
                </h3>
                <br />
                <div className="text">
                  Elektrik Taahhüt Sektöründe Anahtar Teslim Projelerimize Bir
                  Yenisi Daha Ekledik. Karaman İli Merkez 320 Konut Projesi İle
                  Karaman İlinde Devam Eden 3 Projede 1282 Konut Sayısına
                  Ulaştık.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default news;
