import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import {
  BsFillBrightnessHighFill,
  BsFillCpuFill,
  BsFillLightbulbFill,
  BsFillMapFill,
  BsBatteryCharging,
  BsDiagram2Fill,
  BsDisplay,
  BsLightningFill,
  BsPinMap,
  BsPlug,
  BsHammer,
  BsFileSpreadsheetFill,
  BsEyeFill,
  BsLightbulbFill,
} from "react-icons/bs";

import Header from "../../layout/header";
import Footer from "../../layout/footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Project1() {
  const aboutbg = require("../../../assets/images/Proje/Slide.png");
  return (
    <>
      <Header />
      <section class="page-banner">
        <div
          className="page-banner-bg"
          style={{ backgroundImage: "url(" + aboutbg + ")" }}
        ></div>
        <div class="bottom-rotten-curve alternate"></div>

        <div class="auto-container">
          <h1>ANKARA 502 KONUT 32 DERSLİKLİ 1
                  İLKÖĞRETİM OKULU, 1 OTOPARK İNŞAATLARI İLE ALTYAPI VE ÇEVRE
                  DÜZENLEMESİ İŞİ</h1>
          
        </div>
      </section>
      <br />
      <br />
      <br />
      <Container>
        <Row>
          <Col>
            <Row>
              <Col>
                <img
                  src={require("../../../assets/images/Proje-big/Project-01.png")}
                  alt=""
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                {" "}
                <h4>
                  ANKARA İLİ SİNCAN İLÇESİ SARAYCIK MAHALLESİ KENTSEL DÖNÜŞÜM VE
                  GELİŞİM PROJESİ 3.BÖLGE 2.ETAP 502 KONUT, 32 DERSLİKLİ 1
                  İLKÖĞRETİM OKULU, 1 OTOPARK İNŞAATLARI İLE ALTYAPI VE ÇEVRE
                  DÜZENLEMESİ İŞİ ALTYAPI VE ÜSTYAPI ANAHTAR TESLİM ELEKTRİK
                  İŞLERİ
                </h4>
                <br />
                <h4>Anahtar Teslim Elektrik Çözümleri</h4>
                <p>
                  2017 yılında başladığımız 600 iş günü süreli proje kapsamında;
                  15 blok 502 konut, 32 derslikli okul ve otopark yapılarıyla
                  modern ve büyük bir yaşam alanının zayıf akım, kuvvetli akım,
                  otomasyon ve elektronik sistemlerini tamamladık.
                </p>
                <div className="project-border"></div>
                <h4>Proje ve Mühendislik Çözümleri</h4>
                <p>
                  Elektrik taahhüt hizmetimiz kapsamında, işveren kurum nezlinde
                  malzeme seçimleri, onay süreçleri kabul aşamaları ve resmi
                  kurumlar nezlinde enerji telekomünikasyon kabulleri Pier
                  ENERJİ tarafından gerçekleştirilmiştir.
                </p>
              </Col>
            </Row>
          </Col>
          <Col className="project-col-border">
            <h4 className="text-center">Proje Detayları</h4>
            <br />
            <Row>
              <Col md={1}>
                {" "}
                <i class="far fa-calendar-alt"></i>
              </Col>
              <Col md={4}>
                <p>Başlangıç Tarihi</p>
              </Col>
              <Col md={3}>
                <p> 21/12/2017</p>
              </Col>
            </Row>{" "}
            <div className="project-border"></div>
            <Row>
              <Col md={1}>
                {" "}
                <i class="far fa-calendar-alt"></i>
              </Col>
              <Col md={4}>
                <p>Lokasyon</p>
              </Col>
              <Col md={4}>
                <p> Ankara / TÜRKİYE</p>
              </Col>
            </Row>{" "}
            <div className="project-border"></div>
            <Row>
              <Col md={1}>
                {" "}
                <i class="far fa-calendar-alt"></i>
              </Col>
              <Col md={4}>
                <p>Proje Alanı</p>
              </Col>
              <Col md={3}>
                <p>84.000 m2</p>
              </Col>
            </Row>{" "}
            <div className="project-border"></div>
            <Row>
              <Col md={1}>
                {" "}
                <i class="far fa-calendar-alt"></i>
              </Col>
              <Col md={4}>
                <p>Proje Yatırımcısı</p>
              </Col>
              <Col md={5}>
                <p>Çevre Ve Şehircilik Bakanlığı</p>
              </Col>
            </Row>{" "}
            <div className="project-border"></div>
            <Row>
              <Col md={1}>
                {" "}
                <i class="far fa-calendar-alt"></i>
              </Col>
              <Col md={4}>
                <p>Sözleşme Bedeli</p>
              </Col>
              <Col md={3}>
                <p>$1.139.137.66</p>
              </Col>
            </Row>{" "}
            <div className="project-border"></div>
            <Row>
              <Col md={1}>
                {" "}
                <i class="far fa-calendar-alt"></i>
              </Col>
              <Col md={4}>
                <p>Kategori:</p>
              </Col>
              <Col md={4}>
                <p>Tüm Elektrik İşleri</p>
              </Col>
            </Row>{" "}
            <div className="project-border"></div>
            <Row>
              <Col>
                <h4 className="text-center">Proje Sistem Kapsamı</h4>
                <br />
                <p className="text-center">
                  Proje Kapsamında aşağıda belirtilen sistemler tesis
                  edilmiştir.
                </p>
                <Row>
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Topraklama ve Paratoner Sistemi
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span className="ms-1">
                            {" "}
                            <BsFillBrightnessHighFill className="project-icon -none" />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Kablo Taşıma Sistemleri
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span className="ms-1">
                            {" "}
                            <BsFillCpuFill className="project-icon " />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Kuvvetli ve Zayıf Akım Kablolama
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span className="ms-1">
                            {" "}
                            <BsBatteryCharging className="project-icon " />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Elektrik Dağıtım Panoları
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span ref={ref} className="ms-1">
                            {" "}
                            <BsDisplay className="project-icon " />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                </Row>
                <Row>
                  {" "}
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Aydınlatma Sistemleri
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span className="ms-1">
                            {" "}
                            <BsLightningFill className="project-icon -none" />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Yangın Algılama ve Uyarı Sistemi
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span className="ms-1">
                            {" "}
                            <BsPinMap className="project-icon " />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Görüntülü İnterkom Sistemi
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span className="ms-1">
                            {" "}
                            <BsPlug className="project-icon " />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Seslendirme ve Acil Anons Sistemi
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span className="ms-1">
                            {" "}
                            <BsHammer className="project-icon -none" />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                </Row>
                <Row>
                  {" "}
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Data Dağıtım Sistemi
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span className="ms-1">
                            {" "}
                            <BsFileSpreadsheetFill className="project-icon " />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Analog Telefon Sistemi
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span className="ms-1">
                            {" "}
                            <BsEyeFill className="project-icon " />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Access Point Sistemi
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span className="ms-1">
                            {" "}
                            <BsPinMap className="project-icon " />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Headend TV Sistemi
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span className="ms-1">
                            {" "}
                            <BsDisplay className="project-icon " />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                </Row>
                <Row>
                  {" "}
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Mekanik Otomasyon
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span className="ms-1">
                            {" "}
                            <BsLightbulbFill className="project-icon " />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Jeneratör Tesisi (110 kVA)
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span className="ms-1 ">
                            {" "}
                            <BsFillMapFill className="project-icon -none" />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          UPS Tesisi (40 kVA)
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center "
                        >
                          <span className="ms-1 ">
                            {" "}
                            <BsDiagram2Fill className=" project-icon " />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Çevre Aydınlatma Sistemi
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span className="ms-1">
                            {" "}
                            <BsFillLightbulbFill className="project-icon " />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                </Row>
                <Col>
                  {" "}
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        F/O İletişim Altyapısı
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <Button
                        ref={ref}
                        variant="light"
                        {...triggerHandler}
                        className="d-inline-flex align-items-center"
                      >
                        <span className="ms-1">
                          {" "}
                          <BsFillLightbulbFill className="project-icon" />
                        </span>
                      </Button>
                    )}
                  </OverlayTrigger>
                </Col>
              </Col>
            </Row>
          </Col>{" "}
        </Row>{" "}
      </Container>
      <br />
      <Footer />
    </>
  );
}

export default Project1;
