import React from "react";

import Header from "../../layout/header";
import Footer from "../../layout/footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import {
  BsFillBrightnessHighFill,
  BsFillCpuFill,
  // BsFillLightbulbFill,
  // BsFillMapFill,
  BsBatteryCharging,
  // BsDiagram2Fill,
  BsDisplay,
  BsLightningFill,
  BsPinMap,
  BsPlug,
  BsHammer,
  // BsFileSpreadsheetFill,s
  // BsEyeFill,
} from "react-icons/bs";

const aboutbg = require("../../../assets/images/Proje/Slide.png");
export default function Project14() {
  return (
    <>
      <Header />

      {/* <!-- Page Banner Section --> */}
      <section class="page-banner">
        <div
          className="page-banner-bg"
          style={{ backgroundImage: "url(" + aboutbg + ")" }}
        ></div>
        <div class="bottom-rotten-curve alternate"></div>

        <div class="auto-container">
          <h1>UŞAK 729 KONUT, CAMİ İNŞAATI İLE
                  ALTYAPI VE ÇEVRE DÜZENLEMESİ İŞİ</h1>
          
        </div>
      </section>
      <br />
      <br />
      <br />
      <Container>
        <Row>
          <Col>
            <Row>
              <Col>
                <img
                  src={require("../../../assets/images/Proje/11.png")}
                  alt=""
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                {" "}
                <h4>
                  UŞAK İLİ KARAAĞAÇ MAHALLESİ 729 KONUT, CAMİ İNŞAATI İLE
                  ALTYAPI VE ÇEVRE DÜZENLEMESİ İŞİ ANAHTAR TESLİM ELEKTRİK
                  İŞLERİ
                </h4>
                <br />
                <h4>Anahtar Teslim Elektrik Çözümleri</h4>
                <p>
                  2022 yılında başladığımız 60 iş günü süreli proje kapsamında;
                  37 blok 729 konut ve cami yapımı ile oluşturulan modern bir
                  yaşam kompleksinin, kuvvetli akım ve elektronik sistemlerini
                  tamamladık.
                </p>
                <div className="project-border"></div>
                <h4>Proje ve Mühendislik Çözümleri</h4>
                <p>
                  Elektrik taahhüt hizmetimiz kapsamında, işveren kurum nezlinde
                  malzeme seçimleri, onay süreçleri kabul aşamaları ve resmi
                  kurumlar nezlinde enerji telekomünikasyon kabulleri Pier
                  ENERJİ tarafından gerçekleştirilmiştir.
                </p>
              </Col>
            </Row>
          </Col>
          <Col className="project-col-border">
            <h4 className="text-center">Proje Detayları</h4>
            <br />
            <Row>
              <Col md={1}>
                {" "}
                <i class="far fa-calendar-alt"></i>
              </Col>
              <Col md={4}>
                <p>Başlangıç Tarihi</p>
              </Col>
              <Col md={3}>
                <p> 27/07/2022</p>
              </Col>
            </Row>{" "}
            <div className="project-border"></div>
            <Row>
              <Col md={1}>
                {" "}
                <i class="far fa-calendar-alt"></i>
              </Col>
              <Col md={4}>
                <p>Lokasyon</p>
              </Col>
              <Col md={4}>
                <p> Uşak / TÜRKİYE</p>
              </Col>
            </Row>{" "}
            <div className="project-border"></div>
            <Row>
              <Col md={1}>
                {" "}
                <i class="far fa-calendar-alt"></i>
              </Col>
              <Col md={4}>
                <p>Kurulu Güç</p>
              </Col>
              <Col md={4}>
                <p>2000 kW</p>
              </Col>
            </Row>{" "}
            <div className="project-border"></div>
            <Row>
              <Col md={1}>
                {" "}
                <i class="far fa-calendar-alt"></i>
              </Col>
              <Col md={4}>
                <p>Proje Yatırımcısı</p>
              </Col>
              <Col md={5}>
                <p>Çevre ve Şehircilik Bakanlığı</p>
              </Col>
            </Row>{" "}
            <div className="project-border"></div>
            <Row>
              <Col md={1}>
                {" "}
                <i class="far fa-calendar-alt"></i>
              </Col>
              <Col md={4}>
                <p>Sözleşme Bedeli</p>
              </Col>
              <Col md={3}>
                <p>$436.023.21</p>
              </Col>
            </Row>{" "}
            <div className="project-border"></div>
            <Row>
              <Col md={1}>
                {" "}
                <i class="far fa-calendar-alt"></i>
              </Col>
              <Col md={4}>
                <p>Kategori:</p>
              </Col>
              <Col md={4}>
                <p>Elektrik Altyapı İşleri</p>
              </Col>
            </Row>{" "}
            <div className="project-border"></div>
            <Row>
              <Col>
                <h4 className="text-center">Proje Sistem Kapsamı</h4>
                <br />
                <p className="text-center">
                  Proje Kapsamında aşağıda belirtilen sistemler tesis
                  edilmiştir.
                </p>
                <Row>
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Topraklama ve Paratoner Sistemi
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span className="ms-1">
                            {" "}
                            <BsFillBrightnessHighFill className="project-icon -none" />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Kablo Taşıma Sistemleri
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span className="ms-1">
                            {" "}
                            <BsFillCpuFill className="project-icon " />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Kuvvetli Akım Kablolama
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span className="ms-1">
                            {" "}
                            <BsBatteryCharging className="project-icon " />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Aydınlatma Sistemleri
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span ref={ref} className="ms-1">
                            {" "}
                            <BsDisplay className="project-icon " />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                </Row>
                <Row>
                  {" "}
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Headend TV Sistemi
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span className="ms-1">
                            {" "}
                            <BsLightningFill className="project-icon -none" />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          F/O İletişim Altyapısı
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span className="ms-1">
                            {" "}
                            <BsPinMap className="project-icon " />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">OG Kablolama</Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span className="ms-1">
                            {" "}
                            <BsPlug className="project-icon " />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    {" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">Trafo Kurulumu</Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          ref={ref}
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        >
                          <span className="ms-1">
                            {" "}
                            <BsHammer className="project-icon -none" />
                          </span>
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>{" "}
        </Row>{" "}
      </Container>
      <br />
      <Footer />
    </>
  );
}
